'use client';

import { captureException, MentiError } from '@mentimeter/errors/sentry';
import React from 'react';

const DEFAULT_LANG = 'en';

export default function GlobalError({ error }: { error: Error }) {
  React.useEffect(() => {
    captureException(
      new MentiError('Error in root layout', {
        cause: error,
        feature: 'root-layout',
      }),
    );
  }, [error]);

  return (
    <html lang={DEFAULT_LANG}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Something went wrong - Mentimeter</title>
        <style>
          {`
            html, body {
              width: 100%;
              height: 100%;
              font-family: "MentiText";
            }

            @font-face {
              font-family: "MentiText";
              src:
                local("MentiText"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.eot") format("embedded-opentype"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.otf") format("opentype"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.ttf") format("truetype"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.woff") format("woff"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.woff2") format("woff2");
            }

            .wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              text-align: center;
              width: 100%;
              height: 100%;
            }

            .button {
              background-color: rgb(237, 237, 240);
              border: 1px solid rgb(237, 237, 240);
              border-radius: 9999px;
              cursor: pointer;
              font-family: inherit;
              font-weight: 600;
              margin-top: 4px;
              padding-bottom: 16px;
              padding-left: 32px;
              padding-right: 32px;
              padding-top: 16px;
              text-rendering: optimizelegibility;
              text-wrap-mode: nowrap;
              transition-delay: 0s;
              transition-duration: 0.3s;
              transition-property: all;
              transition-timing-function: ease;
            }

            .button:hover {
              border-color: rgb(228, 229, 233);
              background-color: rgb(228, 229, 233);
              color: rgb(22, 22, 23);
            }

            .logo {
              margin-bottom: 1rem;
            }

            .text {
                color: rgba(0, 0, 0, 0.75);
                line-height: 1.4;
                margin: 0 2rem 2rem;
                max-width: 400px;
            }
          `}
        </style>
      </head>
      <body>
        <div className="wrapper">
          <div className="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              preserveAspectRatio="xMidYMid meet"
              width="40"
              height="40"
              aria-label="Mentimeter Logo"
              viewBox="0 0 803.87 676.39"
            >
              <g fill="#000000">
                <polygon
                  fill="#FFB9D0"
                  points="67.06 663 532.44 663 201.01 0 67.06 0 67.06 663"
                ></polygon>
                <polygon
                  fill="#196CFF"
                  points="602.86 0 602.86 133.95 468.91 133.95 468.91 267.9 334.96 267.9 334.96 663 736.81 663 736.81 0 602.86 0"
                ></polygon>
                <path
                  fill="#FF80AB"
                  d="M67.06,267.9V663H468.85C465.23,444.16,286.73,267.9,67.06,267.9Z"
                ></path>
                <path
                  fill="#FF403D"
                  d="M67.06,468.82V662.9h200.8C264.25,555.11,175.74,468.82,67.06,468.82Z"
                ></path>
                <rect y="662.97" width="803.87" height="13.41"></rect>
              </g>
            </svg>
          </div>
          <h1>Something went wrong</h1>
          <p className="text">
            Sorry for the inconvenience. Please try refreshing the page. Contact
            us if the problem persists.
          </p>
          <button
            type="button"
            onClick={() => window.location.reload()}
            className="button"
          >
            Refresh page
          </button>
        </div>
      </body>
    </html>
  );
}
